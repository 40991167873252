import React, { useEffect } from 'react';
import Layout from 'components/layout';
import ImageParser from 'components/imageParser';
import 'stylesheet/home.scss';
import anime from 'animejs';
import { graphql } from 'gatsby';
import { GlobalContext } from '../context/GlobalContext';
import { ResponsiveVH } from 'utils/responsive-vh';
import { ImageLoadClass } from 'utils/loader';

const Index = (props, { location }) => {
  const home_images = props.data.content.frontmatter.images;
  const repeatImagesContainer = [1, 2];

  let home_animFrame = null;
  let home_animeVar = {
    init: false,
    start: 0,
    progress: null,
    duration: 7500,
    image_transition: 1500,
  };

  const home_animStep = (timestamp) => {
    if (!home_animeVar.start) {
      home_animeVar.start = timestamp;
      // console.log('resettime', timestamp);
    }
    home_animeVar.progress = timestamp - home_animeVar.start;
    // INIT TRANSFORM
    if (home_animeVar.progress < home_animeVar.duration) {
      //KEEP GOING
    } else {
      //RESET
      home_animeVar.start = timestamp;
      // console.log('resettime after loop', timestamp);
      imageloop();
    }
    if (home_animeVar.init) {
      home_animFrame = requestAnimationFrame(home_animStep);
    }
  };

  const checkImagesNumber = () => {
    const lc_query = document.querySelectorAll('.landing_container');
    //GET IMAGE NUMBER
    let imagenumber = 0;
    lc_query.forEach((list) => {
      const allimages = list.querySelectorAll('.image_container');
      if (allimages.length > imagenumber) imagenumber = allimages.length;
    });
    return imagenumber;
  };

  const checkActiveContainer = () => {
    let activelc_query = document.querySelector('.landing_container.active');
    if (!activelc_query) {
      //SET INITIAL ACTIVE IF NOTHING IS ACTIVATED
      document.querySelector('.landing_container').classList.add('active');
      activelc_query = document.querySelector('.landing_container.active');
      const showIC_query = activelc_query.querySelectorAll('.image_container');
      if (showIC_query.length > 0) {
        showIC_query.forEach((eachimage) => {
          eachimage.style = null;
          eachimage.classList.add('hidden');
        });
      }
    }
  };

  const imageloop = () => {
    const lc_query = document.querySelectorAll('.landing_container');

    //COMPARE AND ACTIVATE
    if (checkImagesNumber() > 1) {
      //CHECK FOR INITIAL ACTIVE CONTAINER
      checkActiveContainer();
      let activelc_query = document.querySelector('.landing_container.active');
      //SET IMAGES
      let invisibleIC = activelc_query.querySelector('.image_container.hidden');

      const removeHiddenfrom = (target) => {
        anime({
          targets: target,
          opacity: [0, 1],
          duration: home_animeVar.image_transition,
          easing: 'easeInOutSine',
        });
        target.classList.remove('hidden');
      };

      if (invisibleIC) {
        removeHiddenfrom(invisibleIC);
      } else {
        // FINISH LOOPING, RESET
        lc_query.forEach((list) => {
          //RESET ALL IMAGES TO HIDDEN
          const imagesQuery = list.querySelectorAll('.image_container');
          if (imagesQuery.length > 0) {
            imagesQuery.forEach((eachimage) => {
              eachimage.style = null;
              eachimage.classList.add('hidden');
            });
          }

          //TOGGLE ACTIVE  (SWAP CONTAINER)
          if (list.classList.contains('active')) {
            list.classList.remove('active');
          } else {
            list.classList.add('active');
            // GET HIDDEN AND TRIGGER ON ACTIVE CONTAINER
            invisibleIC = list.querySelector('.image_container.hidden');
            if (invisibleIC) {
              removeHiddenfrom(invisibleIC);
            }
          }
        });
      }
    }
  };

  const postloadIndex = () => {
    home_animeVar.init = true;
    home_animFrame = requestAnimationFrame(home_animStep);
  };

  const clearImageLoop = () => {
    const activeLC_query = document.querySelectorAll(
      '.landing_container.active'
    );
    if (activeLC_query.length > 0) {
      activeLC_query.forEach((query) => {
        query.classList.remove('active');
      });
    }
    const showIC_query = document.querySelectorAll('.image_container');
    if (showIC_query.length > 0) {
      showIC_query.forEach((eachimage) => {
        eachimage.style = null;
        eachimage.classList.add('hidden');
      });
    }
    home_animeVar.init = false;
    if (home_animFrame !== null) cancelAnimationFrame(home_animFrame);
  };

  useEffect(() => {
    checkActiveContainer();
    document
      .querySelector('.landing_container.active')
      .querySelector('.image_container.hidden')
      .classList.remove('hidden');

    const fitHeightIndex = new ResponsiveVH({ target: '.fitheightindex' });
    const imageLoader = new ImageLoadClass({
      parent: 'main#home',
      postload: () => {
        if (document.querySelector('main#home'))
          document.querySelector('main#home').classList.remove('imageloading');
      },
    });
    return () => {
      clearImageLoop();
      fitHeightIndex.kill();
      imageLoader.kill();
    };
  }, [clearImageLoop]);

  const slideNext = () => {
    if (home_animFrame !== null) cancelAnimationFrame(home_animFrame);
    home_animeVar.start = false;
    imageloop();
    home_animFrame = requestAnimationFrame(home_animStep);
  };
  return (
    <Layout
      location={location}
      mainID={'home'}
      className='fitheightindex imageloading'
      postLoad={postloadIndex}
      osClassName='fitheightindex'
    >
      {repeatImagesContainer.map((i) => (
        <div
          className='landing_container fitheightindex'
          key={i}
          role='presentation'
          onClick={slideNext}
          onKeyDown={(e) => {
            if (e.which === 32) slideNext();
          }}
        >
          {home_images.map((image, k) => (
            <div
              className={`image_container ${image.half ? 'half' : ''} ${
                image.half ? image.half_position : ''
              }`}
              key={k}
            >
              <ImageParser
                className={`${image.position_x}${image.position_y}`}
                style={{
                  width: '100%',
                  height: '100%',
                  background: '#DDDDDD',
                }}
                imgStyle={{
                  objectFit: 'cover',
                  objectPosition: `${image.position_x} ${image.position_y}`,
                  width: '100%',
                  height: '100%',
                }}
                loading='lazy'
                durationfadein={100}
                alt='Brownfox Studio'
                relativepath={image.file.publicURL}
                childImageSharp={image.file.childImageSharp}
              />
            </div>
          ))}
        </div>
      ))}
    </Layout>
  );
};

export const query = graphql`
  {
    content: markdownRemark(
      frontmatter: { issetting: { eq: false }, contenttype: { eq: "landing" } }
    ) {
      frontmatter {
        title
        images {
          file {
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: DOMINANT_COLOR
                layout: FULL_WIDTH
              )
            }
          }
          position_y
          position_x
          half_position
          half
        }
      }
    }
  }
`;

export default Index;